<template>
  <v-container grid-list-lg>
    <v-layout row>
        <v-flex xs12 class="text-xs-center display-1 font-weight-black my-5 color green lighten-5">Ethics & Philosophy</v-flex>
    </v-layout>
    <v-layout row center>
        <v-spacer />
        <v-flex xs11 sm11 md11>
          CALE AI is an open source project using evolionary computing software and privacy preseving techniques to enable decentralized machine learning.
        </v-flex>
        <v-spacer />
        <v-spacer />
    </v-layout>
    <v-layout row center>
        <v-spacer />
        <v-flex xs11 sm11 md11>
          CALE is not very capable right now and goes throught testing before being offered for general use.  Each toolkit has a master switch to put CALE on or off.  We will provide more documenation and an audit trail of our testing before offering new services.
        </v-flex>
        <v-spacer />
        <v-spacer />
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ethics-philosophy'
}
</script>

<style scoped>

</style>
